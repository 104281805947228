import React, {useEffect} from "react";
import "./ResourcesPage.css";
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardActions,
    CardImage,
    CardSubtitle,
    Avatar,
} from "@progress/kendo-react-layout";
import {
    commentIcon,
    heartIcon,
    heartOutlineIcon,
} from "@progress/kendo-svg-icons";
import {Button} from "@progress/kendo-react-buttons";
import analyticsIQDatasheet from "../../Admin/Components/Segmentation/temporaryresources/datasheets/AnalyticsIQ-PeopleCore-Overview.pdf";
import purpleLabDatasheet from "../../Admin/Components/Segmentation/temporaryresources/datasheets/purplelab_audiences_dtc_semcast_1_.pdf";
import salesIntelDatasheet from "../../Admin/Components/Segmentation/temporaryresources/datasheets/SalesIntel_2023_V2.pdf";
import salesIntelTechnoDatasheet from "../../Admin/Components/Segmentation/temporaryresources/datasheets/Sales_Intel_Technographics_2023.pdf";
import analyticsIQLogo from "../../Admin/Components/Segmentation/temporaryresources/images/analyticsIQ.svg";
import purpleLabLogo from "../../Admin/Components/Segmentation/temporaryresources/images/purplelab.svg";
import salesIntelLogo from "../../Admin/Components/Segmentation/temporaryresources/images/salesintel.svg";
import semcastingLogo from "../../Admin/Components/Segmentation/temporaryresources/images/semcasting-logo-00a9c5.svg";
const ResourcesPage = (loggedIn) => {

    // <p><img src="https://embed-ssl.wistia.com/deliveries/4d177c7f5a7cec2c18808d78160f5898.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=006070e0" width="400" height="225" style="width: 400px; height: 225px;"></a></p><p><a href="https://semcasting.wistia.com/medias/g44dqo3bur?wvideo=g44dqo3bur">AudienceDesigner by Semcasting Overview</a></p>
    const cardsData = [
        {
            scrollViewItems:"https://embed-ssl.wistia.com/deliveries/4d177c7f5a7cec2c18808d78160f5898.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=006070e0",
            headerTitle: "AudienceDesigner by Semcasting Overview",
            headerSubtitle: "The First Self-Service Onboarding And Audience Design Platform",
            redirectLink: {
                url: "https://semcasting.wistia.com/medias/g44dqo3bur?wvideo=g44dqo3bur",
            },
            imageCustom:{
                padding: "20px"
            },
            subTitleCustom: {
                padding: "0"
            }
        },
        {
            scrollViewItems: analyticsIQLogo,
            headerTitle: "Analytics IQ",
            headerSubtitle: "Know Your Customers Like You Know  Your Friends",
            redirectLink: {
                url: analyticsIQDatasheet,
            },
            imageCustom:{
                padding: "65px 20px"
            },
            subTitleCustom: {
                padding: "0 0 45px 0"
            }
        },
        {
            scrollViewItems:purpleLabLogo,
            headerTitle: "PurpleLab",
            headerSubtitle: "HealthNexus Direct to Consumer Audiences",
            redirectLink: {
                url: purpleLabDatasheet,
            },
            imageCustom:{
                padding: "20px"
            },
            subTitleCustom: {
                padding: "0 0 45px 0"
            }
        },
        {
            scrollViewItems:salesIntelLogo,
            headerTitle: "SalesIntel",
            headerSubtitle: "Find your people. Build your pipeline.",
            redirectLink: {
                url: salesIntelDatasheet,
            },
            imageCustom:{
                padding: "20px"
            },
            subTitleCustom: {
                padding: "0 0 45px 0"
            }
        },
        {
            scrollViewItems:salesIntelLogo,
            headerTitle: "SalesIntel Techno",
            headerSubtitle: "Introducing Company" +
                "Technographics Data" +
                "from SalesIntel.",
            redirectLink: {
                url: salesIntelTechnoDatasheet,
            },
            imageCustom:{
                padding: "20px"
            },
            subTitleCustom: {
                padding: "0 0 20px 0"
            }
        },
    ];
    const [cards, setCards] = React.useState(cardsData);


    useEffect(() => {

    }, []);
    return (
        <div className={"resources-page-container"}>
            <div
                className={"card-wrapper"}
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                    margin: "10px",
                }}
            >
                {cards.map((card, index) => {
                    return (
                        <div key={index}>
                            <a className={'card-outer hover-scale'} href={card.redirectLink.url} target="_blank">
                            <Card
                                style={{
                                    width: 260,
                                    boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                                    marginTop: "15px",
                                    alignItems: "stretch"
                                }}
                            >
                                <CardImage
                                    src={card.scrollViewItems}
                                    style={{
                                        height: "185px",
                                        width: "auto",
                                        padding: card.imageCustom.padding,
                                        backgroundColor: "rgba(0, 66, 77, 0.3)",
                                    }}
                                />
                                <CardHeader
                                    className="k-hbox"
                                    style={{
                                        background: "transparent",
                                    }}
                                >
                                    <div>
                                        <CardTitle
                                            style={{
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {card.headerTitle}
                                        </CardTitle>
                                        <CardSubtitle
                                            style={{
                                                padding: card.subTitleCustom.padding
                                            }}
                                        >
                                            <p>{card.headerSubtitle}</p>
                                        </CardSubtitle>
                                    </div>
                                </CardHeader>
                            </Card>
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ResourcesPage;