import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  creativeTag,
  layoutRender,
  preFormikAssign,
} from "../../../helpers/ActivateSegmentFormHelpers";
import { ActivateFormBase } from "../../../helpers/ActivateSegmentHelpers";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {
  semcastAxios,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../Utils/Common";
import {
  ACTIVATION_ID_PLACE_HOLDER,
  DELETE_TRACKING_PIXELS, PRICING,
  SEGMENT_ID_PLACE_HOLDER, SEGMENTS_ACTIVATION_QUEUE_DATA,
  SUCCESS_STATUS,
  UPDATE_ACTIVATION
} from "../../../api/constants";
import {Label} from "@progress/kendo-react-labels";
import "./ActivateSegmentForm.css";
import {useHistory} from "react-router-dom";

function ActivateSegmentForm(props) {
  const history = useHistory();
  const { companyData, creativeTags, activateView, ftpConnections } = props.data;
  //companyData.formik.initialValues["creative_tags"] = creativeTags;
  const segmentData = activateView.segment;
  const pricingData = activateView.pricingIndex[companyData.company] || {};
  const [submitDisabled, setSubmitDisabled] = useState(false);
  // pre processing
  const aqData = activateView.activatedFormData[companyData.company]?.aqData;
  // console.log("ActivateSegmentFormaqData=", aqData);
  let activateFormBaseInst = new ActivateFormBase(companyData);
  const options = { segmentData, pricingData, aqData, defaultData: {} };
  // console.log(activateFormBaseInst, segmentData);
  const formik = useFormik(preFormikAssign(activateFormBaseInst, segmentData, aqData));
  const [activationFormLoader, setActivationFormLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [activateFormBase, setActivateFormBase] = useState(activateFormBaseInst);
  const [newActivationClicked, setNewActivationClicked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [enableNewActivationButton, setEnableNewActivationButton] = useState(false);
  const [enableEditButton, setEnableEditButton] = useState(false);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const pixelReceived = aqData?.creative_pixel_received;
  const broadcastReloadEvent = () => {
    props.broadcastReloadEvent();
  };
  const backToDashboard = (e) => {
    history.push("/segmentation-dashboard");
  }
  useEffect(() => {
    async function formInit() {
      setShow(true);
      activateFormBase.initialize(formik, options);
      await activateFormBase.fetchSavableFieldsData();

      // START populate form values with server data
      let formikValues = { ...formik.values };
      Object.keys(activateFormBase.model.initialAttributes).forEach((key) => {
        if (formikValues.hasOwnProperty(key)) {
          formikValues[key] = activateFormBase.model.initialAttributes[key];
        }
      });
      // console.log(formikValues)
      if(activateFormBase?.activationStatus === "ERROR"){
        formikValues.creativeTagIdDropDown = "";
      }

      await formik.setValues(formikValues);
      // END populate form values with server data
      setActivationFormLoader(false);
    }
    formInit().then((r) =>"");
 //Check if page is loaded, to load conditions
    setIsLoaded(true);
    //Check if New Activation should be enabled
    if(aqData !== undefined){
      const [year, month, day] = aqData?.campaign_range_end?.split('-');
      const today = new Date();
      const endDate = new Date(year, month - 1, day);
      setEnableNewActivationButton(today > endDate);
      //Check if Edit Activation should be enabled
      setEnableEditButton(today < endDate);
    }
  }, []);

  //Load conditions after page loaded
  useEffect(()=>{
    if(isLoaded && (activateFormBase?.company === "semcastingmeasurement" || activateFormBase?.company === "instepmeasurement")){
      // Check if the page has already loaded
      if (document.readyState === 'complete') {
        (activateFormBase?.company === "semcastingmeasurement") ? onPageLoad() : onPageLoadInStep();
      } else {
        window.addEventListener('load', onPageLoad);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
      (activateFormBase?.company === "semcastingmeasurement") ? onPageLoad() : onPageLoadInStep();

    }
  },[isLoaded]);

  useEffect(() => {
    //Setting a placeholder value for Stackadapt tile when user selects All Advertiser to pass the backend credentials data check. FD#11489
    if(isLoaded && activateFormBase?.company === "stackadapt" && formik?.values?.advertiser_options === "all_advertisers"){
      formik.values.advertiserId = "0000";
    }else if(isLoaded && activateFormBase?.company === "stackadapt" && formik?.values?.advertiser_options === "single_advertiser"){
      formik.values.advertiserId = "";
    }
  }, [formik?.values?.advertiser_options]);

  useEffect(()=>{
    //FD#11494
    if(isLoaded && formik?.values?.creativeTagId.length > 0){
      formik.values.creativeTagIdDropDown = formik.values.creativeTagId;
    }
  },[formik?.values?.creativeTagId]);
  const onPageLoad = () => {
  //   let isConsumer = activateFormBase.segment.segment_type === 'CONSUMER';
  //   let isThirdPartySegment = activateFormBase.segment.party === 3;
  //   var businessBasics = document.getElementById('business_basics');
  //   var consumerPii = document.getElementById('consumer_pii');
  //   var consumerBasics = document.getElementById('consumer_basics');
  //   var consumerDemographics = document.getElementById('consumer_demographics');
  //   if (isConsumer && isThirdPartySegment) {
  //     consumerPii.disabled = true;
  //     consumerBasics.disabled = false;
  //     consumerDemographics.disabled = true;
  //     businessBasics.disabled = true;
  //   } else {
  //     businessBasics.disabled = isConsumer;
  //     consumerPii.disabled = !isConsumer;
  //     consumerBasics.disabled = !isConsumer;
  //     consumerDemographics.disabled = !isConsumer;
  //   }
  }

  const onPageLoadInStep = () => {
    let isConsumer = activateFormBase.segment.segment_type === 'CONSUMER';
    var businessBasics = document.getElementById('business_basics');
    var consumerBasics = document.getElementById('consumer_basics');
    var consumerDemographics = document.getElementById('consumer_demographics');
    businessBasics.disabled = isConsumer;
    consumerBasics.disabled = !isConsumer;
    consumerDemographics.disabled = !isConsumer;
  }
  function updateDeviceType(obj, newDeviceType) {
    return { ...obj, device_type: newDeviceType };
  }
  const onSubmit = async (e) => {
    setSubmitDisabled(true);
    e.preventDefault();
    // console.log("formik.values Before", formik.values);
    for (const key in formik.values) {
      if (typeof formik.values[key] === 'string') {
        formik.values[key] = formik.values[key].trim();
      }
    }
    console.log("ActivateSegmentFormFormik.valuesAfter", formik.values);
    console.log(activateFormBase.company);
    if(activateFormBase?.company === 'facebook'){
      formik.values = updateDeviceType(formik.values, [formik.values.device_type])
      console.log(formik.values);
    }

    await formik.submitForm();
    setTimeout(async () => {
      if (formik.isValid) {
        console.log("valid")
        try {
          setActivationFormLoader(true);
          activateFormBase.formatFormData();
          let response = await activateFormBase.saveIfValid(formik);
          if (response?.data?.status === SUCCESS_STATUS) {
            showSuccessToastMessage(
              "Success! " +
                segmentData?.segment_name +
                " has been activated with " +
                companyData?.company
            );
            setShow(false);
          } else {
            setSubmitDisabled(false);
            showErrorToastMessage(response?.data?.data || "Unknown Error");
          }
        } catch (e) {
          setSubmitDisabled(false);
          showErrorToastMessage(e || "Unknown Error");
          console.log(e);
        } finally {
          // broadcastReloadEvent();
          setTimeout(() => { window.location.reload(); }, 3500);
          setActivationFormLoader(false);
        }
      }else{
        setSubmitDisabled(false);
        console.log("formik not valid")
      }
    }, 3000);
  };
  // console.log(aqData)
  const onUpdate = async () => {
    setSubmitDisabled(true);
    const list = ["campaign_range_end","creativeTagId"];
    const obj = {};
    const activationId = aqData.id;
// console.log(aqData)

    for (const key in formik.values) {
      if (typeof formik.values[key] === 'string' && list.includes(key) && pixelReceived === false) {
        const imgSrc = "https://idcm.rtactivate.com/tagid/" + formik.values["creativeTagId"] +"/" ;
        const creativeTag = "<img src=\""+ imgSrc + "\" alt=\"\" style=\"display:none !important;\" />"
        obj.campaignEndDate = formik.values["campaign_range_end"];
        obj.creativeTagId = parseInt(formik.values["creativeTagId"]);
        obj.creativeTag = creativeTag;

      }else if(typeof formik.values[key] === 'string' && list.includes(key) && pixelReceived === true){
        const imgSrc = "https://idcm.rtactivate.com/tagid/" + aqData?.creativeTagId +"/" ;
        const creativeTag = "<img src=\""+ imgSrc + "\" alt=\"\" style=\"display:none !important;\" />"

        obj.campaignEndDate = formik.values["campaign_range_end"];
        obj.creativeTagId = parseInt(aqData?.creativeTagId);
        obj.creativeTag = creativeTag;
      }
    }

    const isNotEmpty = (obj) =>{
      return Object.keys(obj).length !== 0;
    }

    setTimeout(async () => {
      // console.log(obj)
      if (isNotEmpty(obj)) {
        // console.log(obj)
        try {
          setActivationFormLoader(true);
          // activateFormBase.formatFormData();
          // let response = await activateFormBase.saveIfValid(formik);
          let response = await semcastAxios.put(UPDATE_ACTIVATION.replace(ACTIVATION_ID_PLACE_HOLDER, activationId), obj,{withCredentials: true});

          console.log(response)
          if (response?.status === 200) {
            showSuccessToastMessage("Success! " + companyData?.company + " activation has been updated!", 5);
            setShow(false);
          } else {
            setSubmitDisabled(false);
            showErrorToastMessage("Error: Response code " + response?.status || "Unknown Error", 10);
          }
        } catch (e) {
          setSubmitDisabled(false);
          showErrorToastMessage("Error encountered: " + e || "Unknown Error", 10);
          console.log(e);
        } finally {
          // broadcastReloadEvent();
          setTimeout(() => { window.location.reload(); }, 5500);
          setActivationFormLoader(false);
          setSubmitDisabled(false);
        }
      }else{
        setSubmitDisabled(false);
        console.log("formik not valid")
      }

    }, 3000);
    // await semcastAxios.get(SEGMENTS_ACTIVATION_QUEUE_DATA.replace(SEGMENT_ID_PLACE_HOLDER, activationId), {withCredentials: true})
    //     .then(function (response) {
    //       console.log("get: " + response)
    //     })
  };
  const onCancel = () => {
    //setShow(false)
    // props.broadcastFormCloseEvent(null);
    // props.broadcastReloadEvent();
    if(window.location.pathname === '/segmentation-activate'){
      props.broadcastFormCloseEvent(null);
    }else if (window.location.pathname === '/activate-form'){
      history.push("/segmentation-dashboard");
    }
  };

  const onNewActivationClicked = async () => {
    setNewActivationClicked(true);
    activateFormBase.disabled = false;
    activateFormBase.updateEndDateDisabled = false;
    activateFormBase.updateCreativeTagDisabled = false;
    activateFormBase.activationStatus = "New Activation";
    await activateFormBase.resetForm(formik);
  };
  const onEditClicked = async () => {
    setEditButtonClicked(true);
    if(pixelReceived){
    //Cannot change creative tag if pixelReceived is true.
    }else{
      activateFormBase.activationStatus = "Editing";
      activateFormBase.updateCreativeTagDisabled = false;
    }
    activateFormBase.updateEndDateDisabled = false;

    // await activateFormBase.resetForm(formik);
  };
console.log(activateFormBase.isActivated())

  return (
    <>
      {show && (
        <div className="card">
          <div className="card-body">
            <LoadOverlay active={activationFormLoader}>
              <div className="row">
                <div className="col-md-12">
                  <h3 className="w-100">{activateFormBase?.companyText}</h3>
                  {activateFormBase?.activationStatus && (
                    <span
                      className={`status-label  ${
                        activateFormBase?.isError()
                          ? "status-label-error"
                          : activateFormBase?.isActivated()
                          ? "status-label-success"
                          : "status-label-progress"
                      }`}
                    >
                      {activateFormBase.getStatusMessage()}
                    </span>
                  )}
                  <h4 className="mt-3">
                    Segment Name: {segmentData?.segment_name}
                  </h4>
                  {activateFormBase.showAlert && (
                    <div
                      className={`alert alert-${activateFormBase.alert?.type}`}
                      role="alert"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: activateFormBase.alert?.msg,
                        }}
                      />
                    </div>
                  )}
                  <div className="mt-4 pb-3 instruction" dangerouslySetInnerHTML={{
                    __html: activateFormBase?.instructions
                  }}></div>
                  {activateFormBase?.loginLink && (
                    <h6 className="pb-3"
                      dangerouslySetInnerHTML={{
                        __html: activateFormBase?.loginLink,
                      }}
                    />
                  )}
                </div>
                {/*form fields start */}
                <div className="row">
                  {layoutRender(
                    activateFormBase.layout,
                    formik,
                    activateFormBase,
                      segmentData,
                      creativeTags,
                      ftpConnections,
                  )}
                </div>
                <div className="col-12 text-right">
                  {(
                  <button
                    type="button"
                    onClick={() => {
                      onCancel();
                    }}
                    className="btn btn-primary light btn-rounded"
                  >
                    {!newActivationClicked && activateFormBase.isActivated() && window.location.pathname === '/activate-form' ? 'Back To Dashboard' : 'Cancel'}
                  </button>
                  )}
                  {/*{!newActivationClicked && activateFormBase.isActivated() && window.location.pathname === '/activate-form' &&(*/}
                  {/*  <button*/}
                  {/*    type="button"*/}
                  {/*    onClick={backToDashboard}*/}
                  {/*    className="btn btn-rounded btn-primary btn-block"*/}
                  {/*  >*/}
                  {/*    Back To Dashboard*/}
                  {/*  </button>*/}
                  {/*)}*/}
                  {!newActivationClicked && activateFormBase.isActivated() && enableNewActivationButton &&  (
                      <button
                          type="button"
                          onClick={async () => {
                            await onNewActivationClicked();
                          }}
                          className="btn btn-rounded btn-primary btn-block"
                          style={{display:"none"}}
                      >
                        New Activation
                      </button>
                  )}
                  {!newActivationClicked && !editButtonClicked && activateFormBase.isActivated() && enableEditButton &&  (
                      <button
                          type="button"
                          onClick={async () => {
                            await onEditClicked();
                          }}
                          className="btn btn-rounded btn-primary btn-block"
                      >
                        Edit
                      </button>
                  )}
                  {(editButtonClicked) && (
                      <button
                          type="button"
                          onClick={async () => {
                            await onUpdate();
                          }}
                          className="btn btn-rounded btn-primary btn-block"
                          disabled={submitDisabled}
                      >
                        Update
                      </button>
                  )}
                  {(!activateFormBase.isActivated() ||
                    newActivationClicked) && !editButtonClicked && (
                    <button
                      type="button"
                      onClick={async (e) => {
                        await onSubmit(e);
                      }}
                      className="btn btn-rounded btn-primary btn-block"
                      disabled={submitDisabled}
                    >
                      Submit
                    </button>
                  )}
                </div>
                {/*form fields end */}
              </div>
            </LoadOverlay>
          </div>
        </div>
      )}
    </>
  );
}

export default ActivateSegmentForm;
